import React, { useEffect, useState } from 'react';
import { Button, Card, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FeatureModal from '../modals/FeatureModal';
import { landingPageAsset } from '../../utils/s3';

const FeatureCard = ({ feature }) => {
  const { t } = useTranslation();
  const [imagePath, setImagePath] = useState('');
  const [featureModal, setFeatureModal] = useState(false);

  useEffect(() => {
    setImagePath(landingPageAsset('features/icons', feature.type, feature.extension, feature.local ?? false));
  }, [feature]);

  return (
    <Wrapper>
      <Card className="feature-card p-3 mb-4 text-center bg-transparent">
        <div onClick={() => setFeatureModal(true)}>
          <RoundImage src={imagePath} className="img-center img-fluid shadow shadow-lg--hover" />

          <div className="pt-4 text-center">
            <h5 className="title">
              <span className="d-block mb-1" style={{ fontSize: '20px' }}>
                {t(`features.title.${feature.type}`)}
              </span>
              <small className="h4 text-muted">{t(`features.subtitle.${feature.type}`)}</small>
            </h5>
          </div>

          <Button variant="white" size="lg" className="more-info-btn px-4 mt-2">
            {t('moreInfo')}
          </Button>
        </div>
      </Card>

      <FeatureModal showModal={featureModal} closeModal={() => setFeatureModal(false)} feature={feature} />
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .feature-card {
    border: 2px solid ${({ theme }) => theme.business.main};
    border-radius: 8px;
  }

  .more-info-btn {
    border: 2px solid ${({ theme }) => theme.business.main};
    border-radius: 32px;
    color: ${({ theme }) => theme.business.main};
    font-weight: bold;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

    &:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
  }
`;

const RoundImage = styled(Image)`
  height: 215px;
  width: 215px;
  object-fit: cover;
  border: 2px solid #0277BD;
  border-radius: 50%;
`;

export default FeatureCard;