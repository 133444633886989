import React from 'react';
import { Modal } from 'react-bootstrap';
import { ModalWrapper } from './styles';
import LoadingSpinner from '../utils/LoadingSpinner';

const LoadingModal = ({ showModal }) => {
  return (
    <Modal show={showModal} size="sm" centered>
      <Modal.Body>
        <ModalWrapper>
          <div className="centered-section">
            <LoadingSpinner />
          </div>
        </ModalWrapper>
      </Modal.Body>
    </Modal>
  );
};

export default LoadingModal;