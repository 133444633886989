import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from "styled-components";
import { getDir, getLang } from "./dictionaries/utils";
import Footer from "./layout/Footer";
import GoToTopButton from "./layout/GoToTopButton";
import Header from "./layout/Header";
import ScrollToTop from "./layout/ScrollToTop";
import ErrorPage from "./pages/ErrorPage";
import HomePage from "./pages/HomePage";
import { GlobalStyle } from "./styles/GlobalStyle";
import { theme } from "./styles/theme";
import LegalPage from "./pages/LegalPage";
import SignupPage from "./pages/SignupPage";

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.documentElement.dir = getDir(i18n);
    document.documentElement.lang = getLang(i18n);
  }, [i18n]);

  return (
    <ThemeProvider theme={theme} dir={getDir(i18n)}>
      <BrowserRouter>
        <GlobalStyle />
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/legal/terms" element={<LegalPage type="terms" />} />
          <Route path="/legal/privacy" element={<LegalPage type="privacy" />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <GoToTopButton />
        <ScrollToTop />
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;