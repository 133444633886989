import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { landingPageAsset } from '../../utils/s3';

const features = [
  {
    image: landingPageAsset('', 'website', 'svg'),
    title: 'mainFeatures.feature.website',
    description: 'mainFeatures.description.website'
  },
  {
    image: landingPageAsset('', 'application', 'svg'),
    title: 'mainFeatures.feature.application',
    description: 'mainFeatures.description.application'
  },
  {
    image: landingPageAsset('', 'coupons', 'svg'),
    title: 'mainFeatures.feature.coupons',
    description: 'mainFeatures.description.coupons'
  },
  {
    image: landingPageAsset('', 'control-panel', 'svg'),
    title: 'mainFeatures.feature.controlPanel',
    description: 'mainFeatures.description.controlPanel'
  }
]

const MainFeatures = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <h3 className="text-center">{t('mainFeatures.title.small')}</h3>
      <h2 className="text-center">{t('mainFeatures.title.large')}</h2>

      <Row>
        {
          features.map((feature, index) => (
            <Col key={index} sm={12} md={6} lg={3}>
              <Card className="mx-1 feature-card">
                <img src={feature.image} alt={t(feature.title)} className="feature-image img-center img-fluid" />
                <Card.Body>
                  <h2 className="text-center">{t(feature.title)}</h2>
                  <h3 className="text-center">{t(feature.description)}</h3>
                </Card.Body>
              </Card>
            </Col>
          ))
        }
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 5rem 5rem;

  h3 {
    font-size: 2.5rem;
  }

  .feature-card {
    margin-top: 16px;
    margin-bottom: 16px;
    padding-bottom: 8px;
    border: 3px solid ${({ theme }) => theme.applisales.blue};
    border-radius: 8px;
    height: 90%;

    h2 {
      font-size: 3.5rem;
      font-weight: bold;
    }
  }

  .feature-image {
    margin: 16px;
    height: 25vh;
    max-width: 100%;
  }
`;

export default MainFeatures