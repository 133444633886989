import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import english from './english';
import hebrew from './hebrew';

const resources = {
  en: {
    translation: english
  },
  he: {
    translation: hebrew
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "he",
  interpolation: {
    escapeValue: false
  }
});

export default i18n;