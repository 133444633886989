import React, { useContext } from 'react';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Card from 'react-bootstrap/Card';
import { Trans, useTranslation } from 'react-i18next';
import { FaPlus, FaMinus } from 'react-icons/fa';
import styled from 'styled-components';

const questions = [
  'whatIsAppliSales',
  'whatsSpecial',
  'customersLoyalty',
  'receiveOrders',
  'includeCreditCard',
  'operateAppAndWebsite',
  'websiteAddress',
  'appStoreDetails'
];

const QuestionToggle = ({ eventKey, callback, question }) => {
  const { t } = useTranslation();
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <h3 onClick={decoratedOnClick} className="question-title m-3">
      {isCurrentEventKey ? <FaMinus /> : <FaPlus />} {t(`questions.${question}.question`)}
    </h3>
  );
};

const QASection = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <h2 className="text-center mb-2">{t('questions.title')}</h2>

      <Accordion>
        {
          questions.map((question, index) => (
            <Card key={index}>
              <Card.Header>
                <QuestionToggle eventKey={index} question={question} />
              </Card.Header>
              <Accordion.Collapse eventKey={index}>
                <Card.Body>
                  <p className="answer-text m-2">
                    <Trans i18nKey={`questions.${question}.answer`} components={{
                      b: <b />
                    }} />
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))
        }
      </Accordion>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 3rem 6rem;

  .question-title {
    font-size: 2.25rem;
    font-weight: bold;
    cursor: pointer;
  }

  .answer-text {
    font-size: 2rem;
  }
`;

export default QASection;