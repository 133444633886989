import React from "react";

const RenderTextWithLineBreaks = ({ text }) => {
  const textParts = text.split(/<br\s*\/?>/i);

  return (
    <>
      {textParts.map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {index !== textParts.length - 1 && <br />}
        </React.Fragment>
      ))}
    </>
  );
};

export default RenderTextWithLineBreaks;