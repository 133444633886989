import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ModalWrapper, modalStyles } from './styles';

const SuccessModal = ({ showModal, closeModal, successTitle }) => {
  const { t } = useTranslation();

  return (
    <Modal show={showModal} size="lg" centered onHide={closeModal}>
      <Modal.Body>
        <ModalWrapper>
          <h3 className="mt-3 option-title">{t(successTitle)}</h3>
        </ModalWrapper>
      </Modal.Body>

      <Modal.Footer>
        <Button style={modalStyles.footer.button} onClick={closeModal}>{t('ok')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessModal;