import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

const VideoPlayer = ({ url, width, height, thumbnail = false }) => {
  return (
    <Wrapper>
      <div className="player-wrapper">
        <ReactPlayer
          url={url}
          width={width}
          height={height}
          controls
          playing={false}
          light={thumbnail}
          volume={0.5}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .player-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export default VideoPlayer;
