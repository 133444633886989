import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Row, Col } from 'react-bootstrap';
import { theme } from '../../styles/theme';

const values = [
  {
    value: 84,
    title: 'customersClub'
  },
  {
    value: 83,
    title: 'clubAttribution'
  },
  {
    value: 77,
    title: 'longTerm'
  }
];

const progressBarStyle = {
  path: {
    stroke: theme.business.main
  },
  text: {
    fill: theme.business.main
  }
};

const LoyaltySection = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <h2 className="loyalty-title">{t('customersLoyalty.title')}</h2>
      <h2 className="loyalty-subtitle">{t('customersLoyalty.subtitle')}</h2>

      <Row>
        {
          values.map((value, i) => (
            <Col key={i} sm={12} lg={4}>
              <div className="value-container">
                <div className="progress-bar">
                  <CircularProgressbar value={value.value} maxValue={100} text={`${value.value}%`} styles={progressBarStyle} />
                </div>
                <h3 className="progress-bar-title">{t(`customersLoyalty.values.${value.title}`)}</h3>
              </div>
            </Col>
          ))
        }
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 5rem 5rem;

  .loyalty-title {
    font-size: 5rem;
    font-weight: bold;
    text-align: center;
  }

  .loyalty-subtitle {
    font-size: 3.5rem;
    text-align: center;
    margin: 1.5rem 0;
  }

  .value-container {
    margin: 16px;
  }

  .progress-bar {
    height: 30vh;
  }

  .progress-bar-title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-top: 1.5rem;
  }
`;

export default LoyaltySection;