import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { emailRegex, phoneRegex } from '../../utils/validation';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'react-bootstrap';
import { sendContactForm } from '../../functions/contact';
import SuccessModal from '../modals/SuccessModal';

const initialValues = {
  fullName: '',
  businessName: '',
  phoneNumber: '',
  emailAddress: '',
  agreeToTerms: false
};

const ContactForm = () => {
  const { t } = useTranslation();
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required(t('form.errors.fullNameRequired')),
    businessName: Yup.string().required(t('form.errors.businessNameRequired')),
    phoneNumber: Yup.string().matches(phoneRegex, t('form.errors.phoneNumberInvalid')).required(t('form.errors.phoneNumberRequired')),
    emailAddress: Yup.string().matches(emailRegex, t('form.errors.emailInvalid')).required(t('form.errors.emailRequired')),
    agreeToTerms: Yup.bool().oneOf([true], t('form.errors.termsRequired'))
  });

  const completeForm = async (values, { setSubmitting }) => {
    let result = await sendContactForm(values);

    setSubmitting(false);
    if (result) {
      setSuccessModal(true);
    } else {
      setErrorModal(true);
    }
  };

  return (
    <Wrapper id="contact-form">
      <Row>
        <Col sm={12} md={6}>
          <TitleContainer>
            <h1>{t('form.title.contactTitle')}</h1>
            <h2>{t('form.title.contactSubtitle')}</h2>
          </TitleContainer>
        </Col>

        <Col sm={12} md={6}>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={completeForm}>
            {({ isSubmitting, errors, touched }) => (
              <StyledForm>
                <label className="field-label" htmlFor="fullName">{t('form.fields.fullName')}</label>
                <StyledField name="fullName" type="text" />
                <StyledErrorMessage>
                  <ErrorMessage name="fullName" />
                </StyledErrorMessage>

                <label className="field-label" htmlFor="businessName">{t('form.fields.businessName')}</label>
                <StyledField name="businessName" type="text" />
                <StyledErrorMessage>
                  <ErrorMessage name="businessName" />
                </StyledErrorMessage>

                <label className="field-label" htmlFor="phoneNumber">{t('form.fields.phoneNumber')}</label>
                <StyledField name="phoneNumber" type="text" />
                <StyledErrorMessage>
                  <ErrorMessage name="phoneNumber" />
                </StyledErrorMessage>

                <label className="field-label" htmlFor="emailAddress">{t('form.fields.email')}</label>
                <StyledField name="emailAddress" type="email" />
                <StyledErrorMessage>
                  <ErrorMessage name="emailAddress" />
                </StyledErrorMessage>

                <div className="terms-checkbox">
                  <Field type="checkbox" name="agreeToTerms" />
                  {' '}
                  <Trans i18nKey="form.fields.agreeTerms" components={{
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    1: <a href="/legal/terms" />,
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    2: <a href="/legal/privacy" />
                  }} />
                </div>
                <StyledErrorMessage>
                  <ErrorMessage name="agreeToTerms" />
                </StyledErrorMessage>

                <StyledButton type="submit" disabled={isSubmitting}>
                  {t('form.actions.send')}
                </StyledButton>
              </StyledForm>
            )}
          </Formik>
        </Col>
      </Row>

      <SuccessModal showModal={successModal} closeModal={() => setSuccessModal(false)} successTitle={'form.completionMessages.success'} />
      <SuccessModal showModal={errorModal} closeModal={() => setErrorModal(false)} successTitle={'form.completionMessages.error'} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #f3f4f6;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin: 20px auto;
  width: 90%;

  .field-label {
    font-size: 2rem;
  }

  .terms-checkbox {
    font-size: 2rem;
  }
`;

const TitleContainer = styled.div`
  text-align: center;
  padding: 20px;

  h1 {
    font-size: 5rem;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 3.5rem;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const StyledField = styled(Field)`
  font-size: 14px;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  width: 300px;
`;

const StyledButton = styled(Button)`
  font-size: 2rem;
  font-weight: bold;
  background-color: ${({ theme }) => theme.business.main};
  border-color: ${({ theme }) => theme.business.main};
  color: white;
  border-width: 3px;
  border-radius: 5px;
  padding: 0.5rem 2rem;
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.business.background};
    border-color: ${({ theme }) => theme.business.buttons};
    color: ${({ theme }) => theme.business.buttons};
  }
`;

const StyledErrorMessage = styled.div`
  color: red;
  margin-bottom: 10px;
  font-size: 1.25rem;
`;

export default ContactForm;
