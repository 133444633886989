import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { emailRegex, passwordRegex, phoneRegex } from '../../utils/validation';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'react-bootstrap';
import SuccessModal from '../modals/SuccessModal';

const initialValues = {
  businessName: '',
  businessType: '',
  businessAddress: '',
  vatNumber: '',
  fullName: '',
  phoneNumber: '',
  emailAddress: '',
  password: '',
  logo: null
};

const colors = [
  {
    name: 'blue',
    color: '#03A9FA'
  },
  {
    name: 'navyblue',
    color: '#005080'
  },
  {
    name: 'green',
    color: '#8BC34A'
  },
  {
    name: 'bottlegreen',
    color: '#008C00'
  },
  {
    name: 'yellow',
    color: '#F2DC0E'
  },
  {
    name: 'red',
    color: '#FF5722'
  },
  {
    name: 'strongred',
    color: '#CA1500'
  },
  {
    name: 'orange',
    color: '#FF9800'
  },
  {
    name: 'brown',
    color: '#945200'
  },
  {
    name: 'pink',
    color: '#E91E93'
  },
  {
    name: 'purple',
    color: '#9C27B0'
  },
  {
    name: 'black',
    color: '#303030'
  },
  {
    name: 'gray',
    color: '#607D8B'
  }
];

const SignupForm = ({ completeFunction }) => {
  const { t } = useTranslation();
  const logoInputRef = useRef(null);
  const [theme, setTheme] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const validationSchema = Yup.object().shape({
    businessName: Yup.string().required(t('form.errors.businessNameRequired')),
    businessType: Yup.string().required(t('form.errors.businessTypeRequired')),
    businessAddress: Yup.string().required(t('form.errors.businessAddressRequired')),
    vatNumber: Yup.string().required(t('form.errors.vatNumberRequired')),
    fullName: Yup.string().required(t('form.errors.fullNameRequired')),
    phoneNumber: Yup.string().matches(phoneRegex, t('form.errors.phoneNumberInvalid')).required(t('form.errors.phoneNumberRequired')),
    emailAddress: Yup.string().matches(emailRegex, t('form.errors.emailInvalid')).required(t('form.errors.emailRequired')),
    password: Yup.string().matches(passwordRegex, t('form.errors.passwordInvalid')).required(t('form.errors.passwordRequired'))
  });

  const handleLogoBtnClick = () => {
    logoInputRef.current.click();
  };

  const handleFileChange = (event, setFieldValue) => {
    try {
      const file = event.currentTarget.files[0];
      setFieldValue('logo', file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      setFieldValue('logo', null);
    }
  };

  const completeForm = (values, { setSubmitting }) => {
    completeFunction(values, JSON.parse(theme).name, imagePreview);
    setSubmitting(false);
  };

  return (
    <Wrapper>
      <Headline>{t('signup.title.signupForm')}</Headline>
      <Subtitle>{t('signup.subtitle.signupForm')}</Subtitle>

      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={completeForm}>
        {({ isSubmitting, setFieldValue, errors, touched }) => (
          <StyledForm>
            <Row className="justify-content-center">
              <Col sm={12}>
                <FormTitle>{t('form.title.businessDetails')}</FormTitle>
              </Col>

              <Col sm={12} md={6}>
                <label className="field-label" htmlFor="businessName">{t('form.fields.businessName')}</label>
                <StyledField name="businessName" type="text" onChange={event => setFieldValue('businessName', event.target.value)} />
                <StyledErrorMessage>
                  <ErrorMessage name="businessName" />
                </StyledErrorMessage>
              </Col>

              <Col sm={12} md={6}>
                <label className="field-label" htmlFor="businessType">{t('form.fields.businessType')}</label>
                <StyledField as="select" name="businessType" onChange={event => setFieldValue('businessType', event.target.value)}>
                  <option value="">{t('form.fields.businessType')}</option>
                  <option value="Store">{t('form.values.store')}</option>
                  <option value="Restaurant">{t('form.values.restaurant')}</option>
                </StyledField>
                <StyledErrorMessage>
                  <ErrorMessage name="businessType" />
                </StyledErrorMessage>
              </Col>

              <Col sm={12} md={6}>
                <label className="field-label" htmlFor="businessAddress">{t('form.fields.businessAddress')}</label>
                <StyledField name="businessAddress" type="text" onChange={event => setFieldValue('businessAddress', event.target.value)} />
                <StyledErrorMessage>
                  <ErrorMessage name="businessAddress" />
                </StyledErrorMessage>
              </Col>

              <Col sm={12} md={6}>
                <label className="field-label" htmlFor="vatNumber">{t('form.fields.vatNumber')}</label>
                <StyledField name="vatNumber" type="number" onChange={event => setFieldValue('vatNumber', event.target.value)} />
                <StyledErrorMessage>
                  <ErrorMessage name="vatNumber" />
                </StyledErrorMessage>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col sm={12}>
                <FormTitle>{t('form.title.userDetails')}</FormTitle>
              </Col>

              <Col sm={12} md={6}>
                <label className="field-label" htmlFor="fullName">{t('form.fields.fullName')}</label>
                <StyledField name="fullName" type="text" onChange={event => setFieldValue('fullName', event.target.value)} />
                <StyledErrorMessage>
                  <ErrorMessage name="fullName" />
                </StyledErrorMessage>
              </Col>

              <Col sm={12} md={6}>
                <label className="field-label" htmlFor="phoneNumber">{t('form.fields.phoneNumber')}</label>
                <StyledField name="phoneNumber" type="tel" onChange={event => setFieldValue('phoneNumber', event.target.value)} />
                <StyledErrorMessage>
                  <ErrorMessage name="phoneNumber" />
                </StyledErrorMessage>
              </Col>

              <Col sm={12} md={6}>
                <label className="field-label" htmlFor="emailAddress">{t('form.fields.email')}</label>
                <StyledField name="emailAddress" type="email" onChange={event => setFieldValue('emailAddress', event.target.value)} />
                <StyledErrorMessage>
                  <ErrorMessage name="emailAddress" />
                </StyledErrorMessage>
              </Col>

              <Col sm={12} md={6}>
                <label className="field-label" htmlFor="password">{t('form.fields.password')}</label>
                <StyledField name="password" type="password" onChange={event => setFieldValue('password', event.target.value)} />
                <StyledErrorMessage>
                  <ErrorMessage name="password" />
                </StyledErrorMessage>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col sm={12} md={6}>
                <StyledUploadDiv>
                  <h3 className="theme-title">{t('form.fields.logo')}</h3>
                  <StyledUploadButton onClick={handleLogoBtnClick}>{t('form.actions.upload')}</StyledUploadButton>
                  <input ref={logoInputRef} name="logo" type="file" onChange={(event) => handleFileChange(event, setFieldValue)} style={{ display: 'none' }} />
                  {imagePreview && <ImagePreview src={imagePreview} alt="Preview" />}
                </StyledUploadDiv>
              </Col>

              <Col sm={12} md={7}>
                <label className="field-label" htmlFor="colorPicker">{t('form.fields.chooseTheme')}</label>
                <StyledField as="select" name="colorPicker" onChange={event => setTheme(event.target.value)}>
                  <option value="">{t('form.fields.chooseTheme')}</option>
                  {colors.map(color => (
                    <option key={color.name} value={JSON.stringify(color)}>
                      {t(`form.colors.${color.name}`)}
                    </option>
                  ))}
                </StyledField>
                <StyledErrorMessage>
                  <ErrorMessage name="colorPicker" />
                </StyledErrorMessage>

                {theme != null && <ColorPreview style={{ backgroundColor: JSON.parse(theme).color }} />}
              </Col>

              <Col sm={12} md={12}>
                <StyledButton type="submit" disabled={isSubmitting}>
                  {t('form.actions.send')}
                </StyledButton>
              </Col>
            </Row>
          </StyledForm>
        )}
      </Formik>

      <SuccessModal showModal={successModal} closeModal={() => setSuccessModal(false)} successTitle={'form.completionMessages.success'} />
      <SuccessModal showModal={errorModal} closeModal={() => setErrorModal(false)} successTitle={'form.completionMessages.error'} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.business.background};
  border-radius: 8px;
  margin: 20px auto;
  text-align: center;
  width: 90%;
  align-items: center;
  justify-content: center;
  text-align: center;

  .field-label {
    font-size: 1.75rem;
  }

  .theme-title {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 16px;
  }
`;

const Headline = styled.h1`
  font-size: 5rem;
  padding-top: 20px;
  text-align: center;
  margin: 20px;
  color: ${({ theme }) => theme.business.main};
`;

const Subtitle = styled.h2`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
`;

const FormTitle = styled.h3`
  font-size: 2.5rem;
  text-align: center;
  font-weight: bold;
  margin-top: 12px;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
`;

const StyledField = styled.input`
  font-size: 14px;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
`;

const StyledUploadDiv = styled.div`
  margin: 12px;

  input[type="file"] {
    width: 100%;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
`;

const StyledUploadButton = styled(Button)`
  font-size: 1.5rem;
  font-weight: bold;
  background-color: ${({ theme }) => theme.business.main};
  border-color: ${({ theme }) => theme.business.main};
  color: white;
  border-width: 3px;
  border-radius: 5px;
  cursor: pointer;
  margin: 16px;
  padding: 10px 20px;

  &:hover {
    background-color: ${({ theme }) => theme.business.background};
    border-color: ${({ theme }) => theme.business.buttons};
    color: ${({ theme }) => theme.business.buttons};
  }
`;

const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 200px;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  object-fit: fit;
`;

const ColorPreview = styled.div`
  width: 100%;
  height: 36px;
  margin: 12px 0;
  border-radius: 5px;
`;

const StyledButton = styled(Button)`
  font-size: 2rem;
  font-weight: bold;
  background-color: ${({ theme }) => theme.business.main};
  border-color: ${({ theme }) => theme.business.main};
  color: white;
  border-width: 3px;
  border-radius: 5px;
  cursor: pointer;
  margin: 16px;
  padding: 10px 20px;

  &:hover {
    background-color: ${({ theme }) => theme.business.background};
    border-color: ${({ theme }) => theme.business.buttons};
    color: ${({ theme }) => theme.business.buttons};
  }
`;

const StyledErrorMessage = styled.div`
  color: red;
  font-size: 1.25rem;
`;

export default SignupForm;