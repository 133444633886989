export const dateRegex = /^[0-3]?[0-9]{1}$/;
export const numRegex = /^[0-9]{1,6}\.?[0-9]{0,2}$/;
export const phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const passwordRegex = /^(?=.*[A-Z]).{8,}$/;

export const stringNotEmpty = str => {
  return str != null && str.length > 0;
};

export const formatPhoneNumber = phone => {
  let newPhone = phone;
  if (!phone.startsWith('+')) {
    if (phone.startsWith('0')) {
      newPhone = phone.replace('0', '+972');
    } else {
      newPhone = `+${phone}`;
    }
  }
  return newPhone;
};