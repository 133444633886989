import { publicBackendRequest } from "./backend";

export const registerNewBusiness = async (formValues, theme, imageContents) => {
  let eventType = 'register_new_business';
  let eventData = {
    form_details: formValues,
    theme: theme,
    image: imageContents
  };

  let result = await publicBackendRequest(eventType, eventData);
  return result.data;
};

export const confirmSignup = async (emailAddress, authCode) => {
  let eventType = 'confirm_user_signup';
  let eventData = {
    email_address: emailAddress,
    auth_code: authCode
  };

  let result = await publicBackendRequest(eventType, eventData);
  return result.status;
}