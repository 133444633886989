import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { landingPageAsset } from '../../utils/s3';

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <div className="video-container">
        <video autoPlay loop muted className="video" poster={landingPageAsset('', 'header-placeholder', 'png')}>
          <source src={landingPageAsset('', 'header-video', 'mp4')} type="video/mp4" />
          {t('browserNotSupported')}
        </video>
        <div className="video-overlay">
          <h1>{t('heroSection.title')}</h1>
          <h2>{t('heroSection.subtitle')}</h2>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .video-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .signup-btn {
    margin: 16px;
    font-size: 3rem;
    background-color: ${({ theme }) => theme.applisales.blue};
    border: 2px solid ${({ theme }) => theme.applisales.blue};
    border-radius: 8px;
    padding: 8px;
  }
  
  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .video-overlay {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-30%, -50%);
    color: white;
    text-align: center;
  }
  
  .video-overlay h1 {
    font-size: 4.5em;
    color: white;
  }
  
  .video-overlay h2 {
    font-size: 3.5em;
    color: white;
  }
  
`;

export default HeroSection;