import React from "react";
import styled from "styled-components";
import NavBar from "./NavBar";

const Header = () => {
  return (
    <MainHeader>
      <NavBar />
    </MainHeader>
  );
};

const MainHeader = styled.header`
  padding: 0 4.8rem;
  background-color: ${({ theme }) => theme.business.background};

  .logo {
    height: 5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .navbar-button {
    display: inline-block;
    text-decoration: none;
    font-size: 2rem;
    font-weight: 500;
    color: ${({ theme }) => theme.business.main};
    transition: color 0.3s linear;
    margin: 8px;
  }

  .btn {
    color: white;
  }

  .navbar-brand {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbar-title {
    padding: 12px;
    font-size: 2rem;
    margin-top; 8px;
  }
`;

export default Header;