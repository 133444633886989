import React from 'react';
import HeroSection from '../components/home/HeroSection';
import MainFeatures from '../components/home/MainFeatures';
import ContactForm from '../components/contact/ContactForm';
import TVArticle from '../components/home/TVArticle';
import FeaturesSection from '../components/home/FeaturesSection';
import QASection from '../components/home/QASection';
import LoyaltySection from '../components/home/LoyaltySection';
import GrowBusiness from '../components/home/GrowBusiness';

const HomePage = () => {
  return (
    <>
      <HeroSection />
      <LoyaltySection />
      <GrowBusiness />
      <MainFeatures />
      <ContactForm />
      <TVArticle />
      <FeaturesSection />
      <QASection />
      <ContactForm />
    </>
  );
};

export default HomePage;