import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import FeatureCard from './FeatureCard';

const features = [
  {
    type: 'application',
    extension: 'jpg',
    vertical: true
  },
  {
    type: 'website',
    extension: 'png',
    vertical: true
  },
  {
    type: 'orders',
    extension: 'jpg',
    vertical: true
  },
  {
    type: 'punchCards',
    extension: 'jpg',
    vertical: true
  },
  {
    type: 'coupons',
    extension: 'png',
    vertical: false
  },
  {
    type: 'dashboard',
    extension: 'png',
    vertical: false
  }
]

const FeaturesSection = () => {
  return (
    <Wrapper>
      <Row>
        {
          features.map((feature, index) => (
            <Col key={index} sm={12} md={6} lg={4}>
              <FeatureCard className="feature" feature={feature} />
            </Col>
          ))
        }
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 0 6rem;

  .feature {
    height: 90%;
  }
`;

export default FeaturesSection;