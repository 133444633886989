import React from 'react';
import { Button, Container, Nav, Navbar, Offcanvas, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getDir, getOppDir, languages } from '../dictionaries/utils';
import { theme } from '../styles/theme';
import { logo } from '../utils/constants';
import { FaGlobe } from "react-icons/fa";

const NavBar = () => {
  const [t, i18n] = useTranslation();

  const navigateLogin = () => {
    window.location.href = 'https://console.applisales.com/#/login';
  };

  const changeLanguage = lang => {
    i18n.changeLanguage(lang);
  };

  return (
    <Navbar expand="lg" dir={getOppDir(i18n)} collapseOnSelect={true}>
      <Container fluid>
        <Navbar.Brand href="/" dir={getOppDir(i18n)} className="navbar-brand">
          <img src={logo} alt="Logo" className="logo" />
        </Navbar.Brand>

        <Navbar.Toggle dir={getDir(i18n)} aria-controls="mobile-navbar" style={styles.navbarToggle} />

        <Navbar.Offcanvas dir={getDir(i18n)} id="mobile-navbar" aria-labelledby="mobile-navbar-title" placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="mobile-navbar-title" style={styles.navbarTitle}>
              AppliSales
            </Offcanvas.Title>
          </Offcanvas.Header>

          <Offcanvas.Body>
            <Nav className="justify-content-start flex-grow-1 ps-3">
              <Button className="navbar-button" size="lg" onClick={navigateLogin} style={styles.profileButton}>{t('login')}</Button>

              <NavDropdown title={<FaGlobe />} className="navbar-button" style={styles.navbarButton}>
                {
                  languages.map((lang, index) => (
                    <NavDropdown.Item key={index} onClick={() => changeLanguage(lang)} className="navbar-dropdown-item" style={styles.navbarDropdownItem}>
                      {t(`languages.${lang}`)}
                    </NavDropdown.Item>
                  ))
                }
              </NavDropdown>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

const styles = {
  navbarTitle: {
    fontSize: '3rem',
    margin: 8
  },
  navbarButton: {
    fontSize: '2rem',
    color: theme.business.main,
    margin: 8
  },
  profileButton: {
    margin: 8,
    fontSize: '2rem'
  },
  navbarToggle: {
    fontSize: '2rem'
  },
  navbarDropdownItem: {
    fontSize: '1.5rem',
    marginTop: 8,
    marginBottom: 8
  }
};

export default NavBar;