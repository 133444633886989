import axios from 'axios';

export const postRequest = async (url, data, headers = {}) => {
  try {
    const response = await axios.post(url, data, {
      headers: headers
    });
    return response.data;
  } catch (e) {
    console.log(e);
    return false;
  }
};