import React from 'react';
import styled from 'styled-components';
import PDFViewer from '../components/utils/PDFViewer';
import { legalDocs } from '../utils/constants';

const LegalPage = ({ type }) => {
  return (
    <Wrapper className="container">
      <PDFViewer file={legalDocs[type]} />
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 0 auto;

  .container {
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 100%;
  }
  
  .react-pdf__Document {
    margin: 0 auto;
  }

  .react-pdf__Page__textContent {
    display: none;
  }
`;

export default LegalPage;