import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button, Col, Row } from 'react-bootstrap';
import { Formik, Form, ErrorMessage } from 'formik';
import SuccessModal from '../modals/SuccessModal';

const initialValues = {
  authCode: ''
};

const AuthForm = ({ completeFunction, wrongCode, setWrongCode }) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    authCode: Yup.string().required(t('form.errors.authCodeRequired'))
  });

  const submitForm = (values, { setSubmitting }) => {
    completeFunction(values.authCode);
    setSubmitting(false);
  };

  return (
    <Wrapper>
      <Subtitle>{t('form.title.authCode')}</Subtitle>

      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitForm}>
        {({ isSubmitting, setFieldValue, errors, touched }) => (
          <StyledForm>
            <Row>
              <Col>
                <label className="field-label" htmlFor="authCode">{t('form.fields.authCode')}</label>
                <StyledField name="authCode" type="tel" onChange={event => setFieldValue('authCode', event.target.value)} />
                <StyledErrorMessage>
                  <ErrorMessage name="authCode" />
                </StyledErrorMessage>
              </Col>

              <Col sm={12} md={12}>
                <StyledButton type="submit" disabled={isSubmitting}>
                  {t('form.actions.send')}
                </StyledButton>
              </Col>
            </Row>
          </StyledForm>
        )}
      </Formik>

      <SuccessModal showModal={wrongCode} closeModal={() => setWrongCode(false)} successTitle="form.completionMessages.wrongCode" />
    </Wrapper>
  )
};

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.business.background};
  border-radius: 8px;
  margin: 20px auto;
  text-align: center;
  width: 90%;
  align-items: center;
  justify-content: center;
  text-align: center;

  .field-label {
    font-size: 1.75rem;
  }

  .theme-title {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 16px;
  }
`;

const Subtitle = styled.h2`
  font-size: 3rem;
  text-align: center;
  margin: 20px;
  padding: 20px;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
`;

const StyledField = styled.input`
  font-size: 14px;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
`;

const StyledButton = styled(Button)`
  font-size: 2rem;
  font-weight: bold;
  background-color: ${({ theme }) => theme.business.main};
  border-color: ${({ theme }) => theme.business.main};
  color: white;
  border-width: 3px;
  border-radius: 5px;
  cursor: pointer;
  margin: 16px;
  padding: 10px 20px;

  &:hover {
    background-color: ${({ theme }) => theme.business.background};
    border-color: ${({ theme }) => theme.business.buttons};
    color: ${({ theme }) => theme.business.buttons};
  }
`;

const StyledErrorMessage = styled.div`
  color: red;
  font-size: 1.25rem;
`;

export default AuthForm;