import styled from "styled-components";

export const modalStyles = {
  header: {
    title: {
      fontSize: '2rem',
      margin: 8
    }
  },
  body: {
    spinner: {
      width: '5rem',
      height: '5rem',
      margin: 16
    }
  },
  footer: {
    button: {
      fontSize: '1.75rem',
      paddingRight: 16,
      paddingLeft: 16,
      paddingTop: 8,
      paddingBottom: 8
    }
  }
}

export const ModalWrapper = styled.div`
  .centered-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .option-card {
    background-color: ${({ theme }) => theme.business.background};
    border-color: ${({ theme }) => theme.business.buttons};
    border-width: 3px;
    border-radius: 8px;
  }

  .option-title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
  }

  .option-subtitle {
    font-size: 2.25rem;
    text-align: center;
  }

  .unavailable-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: darkred;
  }

  .unavailable-subtitle {
    font-size: 1.75rem;
    font-weight: 600;
    text-align: center;
    color: darkred;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .form-group {
    width: 80%;
    margin-bottom: 20px;
  }

  .form-control {
    max-width: 600px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1.5rem;
  }

  .code-title {
    font-size: 4rem;
    font-weight: bold;
    text-align: center;
  }

  h2 {
    font-size: 3rem;
    font-weight: bold;
    margin: 2rem 0;
  }
`;