import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import { Button, Col, Row } from 'react-bootstrap';
import { getAlign } from '../../dictionaries/utils';

const terms = {
  'general': [
    'generalDetails',
    'domain',
    'legal'
  ],
  'application': [
    'additionalApp',
    'appUpload'
  ],
  'payments': [
    'onlinePayments'
  ],
  'subscription': [
    'subscription'
  ]
};

const OfferDetails = ({ backFunction, completeFunction }) => {
  const [t, i18n] = useTranslation();

  return (
    <OfferContainer>
      <Headline>{t('signup.title.offerDetails')}</Headline>

      {
        Object.keys(terms).map((subject, index) => (
          <div key={index}>
            <Title style={{ textAlign: getAlign(i18n) }}>{t(`signup.terms.title.${subject}`)}</Title>
            {
              terms[subject].map((term, index) => (
                <Details key={index} style={{ textAlign: getAlign(i18n) }}>{t(`signup.terms.details.${term}`)}</Details>
              ))
            }
          </div>
        ))
      }

      <div>
        <Details style={{ textAlign: getAlign(i18n) }}>
          <Trans i18nKey="signup.terms.details.terms" components={{
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            1: <a href="/legal/terms" />,
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            2: <a href="/legal/privacy" />
          }} />
        </Details>
      </div>

      <Row>
        <Col sm={12} md={6}>
          <StyledBackButton onClick={backFunction}>{t('form.actions.back')}</StyledBackButton>
        </Col>
        <Col sm={12} md={6}>
          <StyledButton onClick={completeFunction}>{t('form.actions.continue')}</StyledButton>
        </Col>
      </Row>
    </OfferContainer>
  );
};

const OfferContainer = styled.div`
  background-color: ${({ theme }) => theme.business.background};
  padding: 20px;
  border-radius: 8px;
  margin: 20px auto;
  margin-top: 32px;
  width: 90%;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Headline = styled.h1`
  font-size: 4rem;
  padding: 20px;
  text-align: center;
`;

const Title = styled.p`
  font-size: 2rem;
  font-weight: bold;
  padding: 0 20px;
`;

const Details = styled.p`
  font-size: 1.75rem;
  padding: 4px 20px;
`;

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.business.main};
  border-color: ${({ theme }) => theme.business.main};
  font-size: 3rem;
  padding: 0.5rem 2rem;
  border-radius: 8px;
  border-width: 3px;
  margin: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.business.background};
    border-color: ${({ theme }) => theme.business.buttons};
    color: ${({ theme }) => theme.business.buttons};
  }
`;

const StyledBackButton = styled(Button)`
  background-color: ${({ theme }) => theme.business.background};
  border-color: ${({ theme }) => theme.business.main};
  color: ${({ theme }) => theme.business.main};
  font-size: 3rem;
  padding: 0.5rem 2rem;
  border-radius: 8px;
  border-width: 3px;
  margin: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.business.main};
    border-color: ${({ theme }) => theme.business.buttons};
    color: ${({ theme }) => theme.business.background};
  }
`;

export default OfferDetails;