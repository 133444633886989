export const languages = ['en', 'he'];

export const getLang = i18n => {
  let lang = i18n.language;
  if (!languages.includes(lang)) {
    lang = 'en';
  }
  return lang;
};

export const getDir = i18n => {
  return i18n.dir();
};

export const getOppDir = i18n => {
  return getDir(i18n).split('').reverse().join('');
};

export const getAlign = i18n => {
  return getDir(i18n) === 'ltr' ? 'left' : 'right';
};

export const getOppAlign = i18n => {
  return getDir(i18n) === 'rtl' ? 'left' : 'right';
};

export const textAlign = i18n => {
  return `text-${getAlign(i18n)}`;
};

export const textOppAlign = i18n => {
  return `text-${getOppAlign(i18n)}`;
};