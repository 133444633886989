import React, { useEffect, useState } from 'react';
import { Button, Col, Image, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ModalWrapper, modalStyles } from './styles';
import styled from 'styled-components';
import { getAlign } from '../../dictionaries/utils';
import RenderTextWithLineBreaks from '../utils/RenderTextWithLineBreaks';
import { landingPageAsset } from '../../utils/s3';

const FeatureModal = ({ showModal, closeModal, feature }) => {
  const [t, i18n] = useTranslation();
  const [imagePath, setImagePath] = useState('');

  useEffect(() => {
    setImagePath(landingPageAsset('features/modals', feature.type, feature.extension, feature.local ?? false));
  }, [feature]);

  return (
    <Modal show={showModal} onHide={closeModal} size="lg" centered>
      <Modal.Body>
        <ModalWrapper>
          <Row>
            <Col sm={12}>
              <h2 className="text-center">{t(`features.title.${feature.type}`)}</h2>
            </Col>

            {feature.vertical ? (
              <>
                <Col sm={12} md={6} className="my-3 text-center">
                  <ModalImage src={imagePath} style={{ height: '60vh' }} />
                </Col>
                <Col sm={12} md={6} className={`my-3 text-${getAlign(i18n)}`}>
                  <h3 className="text-justify">
                    <RenderTextWithLineBreaks text={t(`features.description.${feature.type}`)} />
                  </h3>
                </Col>
              </>
            ) : (
              <>
                <Col className="col-12 my-3 text-center">
                  <ModalImage src={imagePath} style={{ width: '100%' }} />
                </Col>
                <Col className={`col-12 mt-3 text-${getAlign(i18n)}`}>
                  <h3 className="text-justify">
                    <RenderTextWithLineBreaks text={t(`features.description.${feature.type}`)} />
                  </h3>
                </Col>
              </>
            )}
          </Row>
        </ModalWrapper>
      </Modal.Body>

      <Modal.Footer>
        <Button style={modalStyles.footer.button} onClick={closeModal}>
          {t('close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ModalImage = styled(Image)`
  object-fit: contain;
  border: 3px solid black;
  border-radius: 16px;
`;

export default FeatureModal;