import React from "react";
import styled from "styled-components";
import { FaInstagram } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { FaMailBulk } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";

const socialOptions = [
  {
    icon: <FaMailBulk className="icons" />,
    contact: 'mailto:support@applisales.com'
  },
  {
    icon: <FaFacebook className="icons" />,
    contact: 'https://www.facebook.com/applisales/'
  },
  {
    icon: <FaInstagram className="icons" />,
    contact: 'https://www.instagram.com/applisales/'
  }
];

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <footer>
        <div className="container grid grid-three-column">
          <div className="footer-about">
            <h3 className="footer-title">AppliSales</h3>
            <p>{t('growBusiness.title.everything')}</p>
          </div>

          <div className="footer-social">
            <h3 className="footer-title">{t('talkWithUs')}</h3>
            <div className="footer-social--icons">
              {
                socialOptions.map((option, index) => (
                  <div key={index}>
                    <a href={option.contact} target="_blank" rel="noreferrer">
                      {option.icon}
                    </a>
                  </div>
                ))
              }
            </div>
          </div>
        </div>

        <div className="footer-bottom--section">
          <hr />
          <div className="container grid grid-two-column ">
            <p>
              {new Date().getFullYear()} {t('applisalesLtd')}
            </p>
            <div>
              <a href="/legal/terms">
                <p>{t('legal.terms')}</p>
              </a>
              <a href="/legal/privacy">
                <p>{t('legal.privacyPolicy')}</p>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .signup-btn {
    background-color: ${({ theme }) => theme.business.buttons};
  }

  .contact-short {
    max-width: 60vw;
    margin: auto;
    padding: 5rem 10rem;
    background-color: ${({ theme }) => theme.business.background};
    border-radius: 1rem;
    box-shadow: ${({ theme }) => theme.colors.shadowSupport};
    transform: translateY(50%);

    .grid div:last-child {
      justify-self: end;
      align-self: center;
    }
  }

  footer {
    padding: 6rem 6rem 3rem 6rem;
    background-color: ${({ theme }) => theme.business.main};
    h3 {
      color: ${({ theme }) => theme.colors.hr};
      margin-bottom: 2.4rem;
    }
    p {
      color: ${({ theme }) => theme.colors.white};
    }
    .footer-title {
      font-weight: bold;
    }
    .footer-social--icons {
      display: flex;
      gap: 2rem;

      div {
        padding: 1rem;
        border-radius: 50%;
        border: 2px solid ${({ theme }) => theme.colors.white};

        .icons {
          color: ${({ theme }) => theme.colors.white};
          font-size: 2.4rem;
          position: relative;
          cursor: pointer;
        }
      }
    }
  }

  .footer-bottom--section {
    padding-top: 9rem;

    hr {
      margin-bottom: 2rem;
      color: ${({ theme }) => theme.colors.white};
      height: 0.1px;
    }
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .contact-short {
      max-width: 80vw;
      margin: 4.8rem auto;
      transform: translateY(0%);
      text-align: center;

      .grid div:last-child {
        justify-self: center;
      }
    }

    footer {
      padding: 9rem 0 9rem 0;
    }

    .footer-bottom--section {
      padding-top: 4.8rem;
    }
  }
`;

export default Footer;