export const fileUrl = (path, filename, ext = '') => {
  let url = `https://d20khotxdsyhvh.cloudfront.net/${path}/${filename}`;
  if (ext !== '') {
    url += `.${ext}`;
  }
  return url;
};

export const landingPageAsset = (path, filename, ext = '', local = false) => {
  let cloudfrontPath = 'landing-page';
  if (path !== '') {
    cloudfrontPath = `${cloudfrontPath}/${path}`;
  }

  if (local) {
    return `${path}/${filename}.${ext}`;
  }
  return fileUrl(cloudfrontPath, filename, ext);
};