import React from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const LoadingSpinner = () => {
  return (
    <Wrapper>
      <Row>
        <Col className="spinner-column">
          <Spinner animation="border" role="status" className="spinner" />
        </Col>
      </Row>
    </Wrapper>
  )
};

const Wrapper = styled.div`
  .spinner-col {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .spinner {
    width: 5rem;
    height: 5rem;
    margin: 16px;
  }
`;

export default LoadingSpinner;