import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../styles/Button";
import { useTranslation } from "react-i18next";

const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <div className="container">
        <div>
          <h2>404</h2>
          <h3>{t('oops')}</h3>
          <p>{t('seemsLost')}</p>

          <NavLink to="/">
            <Button className="back-button">{t('backHome')}</Button>
          </NavLink>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .container {
    padding: 9rem 0;
    text-align: center;

    h2 {
      font-size: 10rem;
    }

    h3 {
      font-size: 4.2rem;
    }

    p {
      margin: 2rem 0;
      font-size: 2.5rem;
    }

    .back-button {
      background-color: ${({ theme }) => theme.business.buttons};
    }
  }
`;

export default ErrorPage;