import { publicBackendRequest } from "./backend";

export const sendContactForm = async values => {
  let eventType = 'submit_contact_form';
  let eventData = {
    name: values.fullName,
    business_name: values.businessName,
    phone: values.phoneNumber,
    email: values.emailAddress
  };

  let result = await publicBackendRequest(eventType, eventData);
  return result.status;
};