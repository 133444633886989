import { landingPageAsset } from "./s3";

export const appId = 'Afs828DKA8AB';

export const functions = {
  publicBackend: 'https://hyj74iihfb.execute-api.us-east-1.amazonaws.com/prod/'
};

export const statusCodes = {
  SUCCESS: 0,
  UNKNOWN: 1,
  NOT_FOUND: 2
};

export const videos = {
  tvArticle: 'https://www.youtube.com/embed/JtL_rfeB5WI',
  appDemo: landingPageAsset('demo-videos', 'app-video', 'mp4'),
  websiteDemo: landingPageAsset('demo-videos', 'website-video', 'mp4'),
  managementConsoleDemo: landingPageAsset('demo-videos', 'management-console-video', 'mp4')
};

export const legalDocs = {
  terms: 'https://d20khotxdsyhvh.cloudfront.net/Legal/terms.pdf',
  privacy: 'https://d20khotxdsyhvh.cloudfront.net/Legal/privacy.pdf'
};

export const logo = '/assets/horizontal-logo.png';