import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import VideoPlayer from "../utils/VideoPlayer";
import { videos } from "../../utils/constants";

const TVArticle = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <h1 className="video-title">{t('videos.tvArticle')}</h1>
      <VideoPlayer url={videos.tvArticle} width="80%" height="50vh" />
    </Wrapper>
  );
};

const Wrapper = styled.section`
  margin: 6rem 6rem;

  .video-title {
    font-size: 3.5rem;
    text-align: center;
    margin: 16px;
  }
`;

export default TVArticle;