import React, { useState } from 'react';
import OfferDetails from '../components/signup/OfferDetails';
import SignupForm from '../components/signup/SignupForm';
import { confirmSignup, registerNewBusiness } from '../functions/business';
import AuthForm from '../components/signup/AuthForm';
import LoadingModal from '../components/modals/LoadingModal';

const SignupPage = () => {
  const [signupForm, setSignupForm] = useState(true);
  const [offerDetails, setOfferDetails] = useState(false);
  const [authForm, setAuthForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [theme, setTheme] = useState(null);
  const [imageContents, setImageContents] = useState(null);
  const [wrongCode, setWrongCode] = useState(false);

  const viewOfferDetails = (formValues, theme, imageContents) => {
    setFormValues(formValues);
    setTheme(theme);
    setImageContents(imageContents);

    setSignupForm(false);
    setOfferDetails(true);
  };

  const returnToForm = () => {
    setOfferDetails(false);
    setSignupForm(true);
  };

  const completeSignupForm = async () => {
    setLoading(true);
    await registerNewBusiness(formValues, theme, imageContents);
    setLoading(false);

    setOfferDetails(false);
    setAuthForm(true);
  };

  const completeAuth = async code => {
    setLoading(true);
    let result = await confirmSignup(formValues.emailAddress, code);
    setLoading(false);
    
    if (result) {
      window.location.href = 'https://console.applisales.com/#/login';
    } else {
      setWrongCode(true);
    }
  };

  return (
    <>
      {signupForm && <SignupForm completeFunction={viewOfferDetails} />}
      {offerDetails && <OfferDetails backFunction={returnToForm} completeFunction={completeSignupForm} />}
      {authForm && <AuthForm completeFunction={completeAuth} wrongCode={wrongCode} setWrongCode={setWrongCode} />}

      <LoadingModal showModal={loading} />
    </>
  );
};

export default SignupPage;