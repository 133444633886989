import { functions, statusCodes } from "../utils/constants";
import { postRequest } from "../utils/rest";

export const createEvent = (type, data) => {
  let event = {
    event_type: type,
    data: data
  };
  return event;
};

export const publicBackendRequest = async (type, data) => {
  let event = createEvent(type, data);
  let result = await postRequest(functions.publicBackend, event);
  return backendResponse(result);
};

export const backendResponse = result => {
  let response = { status: false, data: null };

  if (result) {
    let data = JSON.parse(result);
    if (data.status === statusCodes.SUCCESS) {
      response.status = true;
      response.data = data.result;
    }
  }

  return response;
};